import React, { useState, useEffect } from 'react';

const DynamicFields = ({ onFieldsChange, initialData = [] }) => {
  // Ensure that initialData is always an array
  const [fields, setFields] = useState(Array.isArray(initialData) ? initialData.map(value => ({ value })) : []);

  // Update fields when initialData changes
  useEffect(() => {
    if (Array.isArray(initialData)) {
      setFields(initialData.map(value => ({ value })));
    }
  }, [initialData]);

  // Add a change handler to update onFieldsChange when fields change
  useEffect(() => {
    onFieldsChange(fields.map(field => field.value));
  }, [fields]);

  const handleAddField = () => {
    const newFields = [...fields, { value: '' }];
    setFields(newFields);
    onFieldsChange(newFields.map(field => field.value));
  };

  const handleChange = (index, event) => {
    const newFields = fields.map((field, i) => (
      i === index ? { ...field, value: event.target.value } : field
    ));
    setFields(newFields);
    onFieldsChange(newFields.map(field => field.value));
  };

  const handleRemoveField = (index) => {
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
    onFieldsChange(newFields.map(field => field.value));
  };

  return (
    <div>
      {fields.map((field, index) => (
        <div key={index} className="row p-3 d-flex justify-content-between">
          <div className='col-10'>
            <input
              className="form-control"
              type="text"
              value={field.value}
              onChange={(event) => handleChange(index, event)}
            />
          </div>
          <div className="col-2">
            <button className="btn btn-secondary" type="button" onClick={() => handleRemoveField(index)}>-</button>
          </div>
        </div>
      ))}
      <div className="d-flex justify-content-end mx-2">
        <button className="btn btn-primary" type="button" onClick={handleAddField}>Add Field</button>
      </div>
    </div>
  );
};


export default DynamicFields;
